<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col cols="12">
				<h1>Profile</h1>
				<v-divider class="mt-5"></v-divider>
			</v-col>

			<v-col cols="12">
				<v-row justify="space-between">
					<v-col sm="12" md="12" lg="6" xl="6" cols="12">
						
						<v-row align="center" justify="start">
							<v-col sm="12" md="12" lg="12" xl="5" cols="12" class="d-flex justify-center align-center">
								<v-row class="px-5">
									<v-col sm="12" md="12" lg="12" xl="12" cols="12" class="d-flex justify-center">
										<v-img class="profile_pic_bar" :src="$store.state.user.profilePic" max-width="120" transition="fade-transition"></v-img>
									</v-col>

									<v-col sm="12" md="12" lg="12" xl="12" cols="12" class="d-flex justify-center">
										<h4 class="ma-0">
											<strong>Display name</strong>
											: {{ $store.state.user.nickname }}</h4>
										<h4 class="ma-0">
											<strong>Username</strong>
											: {{ $store.state.user.username }}</h4>
									</v-col>
								</v-row>
							</v-col>

							<v-col sm="12" md="12" lg="12" xl="7" cols="12">
								<v-simple-table>
									<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Stream</th>
											<th class="text-left">Points</th>
											<th class="text-left">Watchtime</th>
											<th class="text-left">Chats</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in $store.state.user.watchtime" :key="item.id">
											<td>{{ item.nickname }}</td>
											<td>{{ UserPoints(item.id) }}</td>
											<td>
												{{
													Math.floor((item.time/1000) / 86400) + ' days ' +
													Math.floor((item.time/1000) % 86400 / 3600) + ' hours ' +
													Math.floor((item.time/1000) % 3600 / 60) + ' min '
												}}
											</td>
											<td>{{ $store.state.user.chats[index].messages }}</td>
										</tr>
									</tbody>
									</template>
								</v-simple-table>
							</v-col>

						</v-row>
					</v-col>

					<v-col sm="12" md="12" lg="1" xl="1" cols="12" class="d-flex justify-center align-center">
						<v-divider vertical></v-divider>
					</v-col>

					<v-col sm="12" md="12" lg="5" xl="5" cols="12">
						<h3>Account Connections</h3>
						<v-btn 
							block outlined large
							v-if="$store.state.user.steam_id"
							:disabled="!$store.state.user.steam_id"
							@click="UnlinkSteamId()"
							color="error"
						>
							Unlink Your Steam Account
							<v-icon class="ml-2">mdi-steam</v-icon>
						</v-btn>
						<v-form action="https://steamcommunity.com/openid/login" method="post" v-else>
							<input type="hidden" name="openid.identity" value="http://specs.openid.net/auth/2.0/identifier_select" />
							<input type="hidden" name="openid.claimed_id" value="http://specs.openid.net/auth/2.0/identifier_select" />
							<input type="hidden" name="openid.ns" value="http://specs.openid.net/auth/2.0" />
							<input type="hidden" name="openid.mode" value="checkid_setup" />
							<input type="hidden" name="openid.realm" :value="$store.state.redirect_uri" />
							<input type="hidden" name="openid.return_to" :value="$store.state.redirect_uri + '/dashboard'" />

							<v-btn block outlined large color="white" type="submit">
								Connect your Steam Account
								<v-icon right size="25">mdi-steam</v-icon>
							</v-btn>
						</v-form>

						<h3 class="mt-6">Delete Account</h3>
						<v-dialog v-model="dialogDel" max-width="400">
							<template v-slot:activator="{ on, attrs }">
								<v-btn block outlined large v-bind="attrs" v-on="on" color="red darken-3">
									Delete Account
									<v-icon right size="25">mdi-delete</v-icon>
								</v-btn>
							</template>
							<v-card>
								<v-card-title>
									<v-icon class="mr-2">mdi-alert</v-icon>
									Are you sure about this?
									<v-icon class="ml-2">mdi-alert</v-icon>
								</v-card-title>

								<v-card-text>
									This is a permanent action and cannot be undone! You will lose all points and redeems you have currently.
								</v-card-text>

								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="secondary" @click="dialogDel = false">Cancel</v-btn>
									<v-btn color="error" :loading="loadingBtnDel" :disabled="loadingBtnDel" @click="DeleteUser">Delete</v-btn>
									<v-spacer></v-spacer>
								</v-card-actions>
							</v-card>
						</v-dialog>

					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12">
				<h2 class="mt-12">Redeems</h2>
				
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Title</th>
								<th class="text-left mgn_desc">Description</th>
								<th class="text-left">Points</th>
								<th class="text-left">Amount</th>
								<th class="text-left">Date redeemed</th>
								<th class="text-left">Creator</th>
							</tr>
						</thead>
						
						<tbody>
							<tr v-for="(reward, i) in Rewards" :key="i">
								<td>{{ reward.title }}</td>
								<td class="w">{{ reward.description }}</td>
								<td>
									<v-chip 
										class="justify-center ma-1 mgn_chip"
										v-for="shop in reward.points_requirements" 
										:key="shop.streamer_shop">
										{{ shop.points }} {{ shop.shorthand }} points
									</v-chip>
								</td>
								<td>{{ reward.multiply ? 'x' + reward.multiply : '' }}</td>
								<td>{{ reward.date_redeemed }}</td>
								<td>{{ reward.creator }}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			dialogDel: false,
			loadingBtnDel: false,
			loadingBtnUnlink: false
		}
	},

	computed: {
		Rewards() {
			return [...this.$store.state.user.redeems].reverse();
		},
	},

	methods: {
		UserPoints(id) {
			let points = this.$store.state.user.points.find(u => u.id === id);

			if (points) {
				return points.points;
			}
			else {
				return '-'
			}
		},

		DeleteUser: async function () {
			this.loadingBtnDel = true;

			try {
				const request = await fetch(this.$store.state.base_url + '/user/delete', {
					method: 'DELETE',
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();
				
				if (request.ok) {	
					if (res.isUserDeleted) {
						this.WipeSession();
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Your account has been deleted successfully!'
						});
					}
					else if (res.hasBeenDeleted) {
						this.WipeSession();
						this.$notify({
							group: 'main',
							type: 'warn',
							title: this.$store.state.app_name,
							text: 'Your account has been already deleted!'
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'There was a network error. Please try again.'
				});
			}
			finally {
				this.loadingBtnDel = false;
			}
		},

		async UnlinkSteamId() {
			this.loadingBtnUnlink = true;

			try {
				const request = await fetch(this.$store.state.base_url + '/steam/unlink', {
					method: 'PUT',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					},
				});

				const res = await request.json();

				if (request.ok) {	
					if (res.steam_unlinked) {
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Your Steam ID has been unlinked with MGN successfully!'
						});
					}
					await this.$store.dispatch('fetchUserInfo');
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.error
				});
			}
			finally {
				this.loadingBtnUnlink = false;
			}
		},

		WipeSession: function() {
			// Clear Vuex
			this.$store.state.isLoggedIn = false;
			this.$store.state.isSuperUser = false;
			this.$store.state.user = {};
			// Remove auth cookie
			this.$cookies.remove('mgn_sessid');
			// Route user to /
			this.$router.push('/');
		}
	}
}
</script>

<style scoped>
.mgn_desc {
	width: 500px;
}
</style>